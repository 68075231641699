@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;
  font-family: Roboto, sans-serif;
}

#root {
  height: 100%;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0;  
  background: transparent;  
}
